import {
    ArrayField, BooleanField,
    ChipField,
    Datagrid,
    ImageField,
    List,
    ReferenceArrayField,
    SingleFieldList,
    TextField,
    TextInput,
    useRecordContext
} from 'react-admin';
import React from "react";

const itemFilters = [
    <TextInput label="Suche" source="q" alwaysOn/>];

export const ItemList = (props: any) => (
    <List filters={itemFilters}>
        <Datagrid rowClick="show">
            <TextField source={"owner.displayName"} label={"Gehört"}/>
            <ArrayField source={"images"} perPage={1}>
                <SingleFieldList linkType={false}><ImageField source="src"/></SingleFieldList>
            </ArrayField>
            <TextField source="title"/>
            <ReferenceArrayField source="categories" reference="categories">
                <SingleFieldList>
                    <ChipField source="name"/>
                </SingleFieldList>
            </ReferenceArrayField>
            <TextField source={"location.zip"} label={"PLZ"}/>

            <BooleanField source={"isAvailable"} label={"Verfügbar"}></BooleanField>
        </Datagrid>
    </List>
);