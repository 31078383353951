import {
    BooleanField,
    Count,
    Datagrid,
    List,
    ReferenceArrayField,
    ReferenceField,
    ReferenceManyCount,
    TextField
} from 'react-admin';

export const CategoryList = () => (
    <List>
        <Datagrid rowClick="show">
            <TextField source="name" />
            <ReferenceManyCount
                label={"Unterkategorien"}
                reference="categories"
                target="parentCategory"
            />
            <ReferenceManyCount
                label={"Items in Kategorie"}
                reference="items"
                target="categories"
            />
            <ReferenceField source={"parent"} reference={"categories"}>
                <TextField source="name" />
            </ReferenceField>
            <BooleanField source="active" />
        </Datagrid>
    </List>
);