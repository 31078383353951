// @ts-ignore
// @ts-ignore
const authProvider = {
    login: async ({username, password}) => {
        const request = new Request(`${process.env.REACT_APP_BASE_URL}/identity/self-service/login/browser`, {
            method: 'GET',
            headers: new Headers({'Accept': 'application/json'}),
            credentials: 'include',
        });
        const flow = await fetch(request);

        if (flow) {
            if (flow.status < 200 || flow.status >= 300) throw new Error(flow.statusText);

            const flowData = await flow.json();
            const csrfToken = flowData.ui.nodes.find(node => node.attributes.name === 'csrf_token').attributes.value;

            const loginRequest = new Request(`${process.env.REACT_APP_BASE_URL}/identity/self-service/login?flow=${flowData.id}`, {
                method: 'POST',
                body: JSON.stringify({identifier: username, password: password, csrf_token: csrfToken, method: 'password'}),
                headers: new Headers({'Content-Type': 'application/json'}),
                credentials: 'include',
            });

            const login = await fetch(loginRequest);

            if (login) {
                if (login.status < 200 || login.status >= 300) throw new Error(login.statusText);
                localStorage.setItem('authenticated', true);
                const loginData = await login.json();
                setDisplayname(loginData.session);
                return Promise.resolve();
            } else return Promise.reject();
        } else {
            return Promise.reject();
        }

    },
    logout: async () => {
        const flowRequest = new Request(`${process.env.REACT_APP_BASE_URL}/identity/self-service/logout/browser`, {
            method: 'GET',
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: 'include',
        });

        const flow = await fetch(flowRequest);

        if (flow) {
            if (flow.status === 401) return Promise.resolve();
            if (flow.status < 200 || flow.status >= 300) throw new Error(flow.statusText);

            const flowData = await flow.json();
            const logoutRequest = new Request(`${process.env.REACT_APP_BASE_URL}/identity/self-service/logout?token=${flowData.logout_token}`, {
                method: 'GET',
                headers: new Headers({'Accept': 'application/json'}),
                credentials: 'include',
            });

            const logout = await fetch(logoutRequest);

            if (logout) {
                if (logout.status < 200 || logout.status >= 300) throw new Error(logout.statusText);
                localStorage.removeItem('authenticated');
                localStorage.removeItem('displayname');
                return Promise.resolve();
            } else return Promise.reject();

            return Promise.resolve();
        } else return Promise.resolve();

    },
    checkAuth: async () => {

        const loginRequest = new Request(`${process.env.REACT_APP_BASE_URL}/identity/sessions/whoami`, {
            method: 'GET',
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: 'include',
        });

        const login = await fetch(loginRequest);

        if (login) {
            if (login.status < 200 || login.status >= 300) throw new Error(login.statusText);
            const loginData = await login.json();
            console.log(loginData);
            localStorage.setItem('authenticated', true);
            setDisplayname(loginData);
            return Promise.resolve();
        } else return Promise.reject();
    },

    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('username');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () =>
        Promise.resolve({
            id: 'user',
            fullName: localStorage.getItem('displayname') ?? '',
        }),
    getPermissions: () => Promise.resolve(''),
};

function setDisplayname(loginData) {
    localStorage.setItem('displayname', loginData.identity.traits.name.first + ' ' + loginData.identity.traits.name.last);
}

export default authProvider;