import React from 'react';
import './App.css';
import {
    Admin,
    EditGuesser,
    fetchUtils,
    ListGuesser,
    mergeTranslations,
    Options,
    Resource, ShowGuesser,
    TranslationMessages
} from "react-admin";
import authProvider from "./authentication/authentication_provider";
import {Dashboard} from "./dashboard/dashboard";
import springbootRest from "./spring-data-provider";
import {ItemList} from "./items/items-list";
import germanMessages from 'ra-language-german';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {ItemEdit} from "./items/items-edit";
import {OrderList} from "./orders/order-list";
import {ItemShow} from "./items/items-show";
import {OrderShow} from "./orders/order-show";
import {CategoryList} from "./categories/category-list";
import {CategoryShow} from "./categories/categories-show";
import {CategoryEdit} from "./categories/category-edit";
import {CategoryCreate} from "./categories/category-create";

const messages: { de: TranslationMessages } = {
    'de': germanMessages,
};

const customGermanMessages: TranslationMessages = mergeTranslations(
    messages['de'],
    {
        'ra-tree': {
            drag_preview:
                'Node #%{id} |||| Node #%{id} with %{smart_count} children',
            root_target: 'Drop an item here to make it top level',
            item_moved: 'Item verschoben',
            new_node: 'Neues Item',
            action: {
                add_root: 'Root Item hinzufügen',
                add_child: 'Unteritem hinzufügen',
            },
        },
    });
// @ts-ignore
const i18nProvider = polyglotI18nProvider(() => customGermanMessages, 'de');

const httpClient = (url: string, options: Options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({
            Accept: 'application/json'
        });
    }
    options.credentials = 'include';
    return fetchUtils.fetchJson(url, options);
}


function App() {
    return (
        <Admin
            authProvider={authProvider}
            dataProvider={springbootRest(`${process.env.REACT_APP_BASE_URL}/api`, httpClient)}
            i18nProvider={i18nProvider}
            dashboard={Dashboard}
        >
            <Resource name="categories" list={CategoryList}
                      show={CategoryShow} edit={CategoryEdit}
                      create={CategoryCreate}
                      options={{label: "Kategorien"}}
                      recordRepresentation={"name"} />
            <Resource name="items" show={ItemShow} list={ItemList} edit={ItemEdit} options={{label: "Items"}} />
            <Resource name="mediaitems"/>
            <Resource options={{label: "Transaktionen"}} name={"orders"} list={OrderList} show={OrderShow} />
        </Admin>
    );
}

export default App;
