import {
    BooleanInput,
    Create,
    ReferenceInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import React from "react";

export const CategoryCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceInput reference={"categories"} source={"parent"}></ReferenceInput>
            <BooleanInput source="active" />
        </SimpleForm>
    </Create>
);