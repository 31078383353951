import {BooleanField, ReferenceArrayField, ReferenceField, Show, SimpleShowLayout, TextField} from 'react-admin';

export const CategoryShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="name" />
            <ReferenceArrayField source={"children"} reference={"categories"}></ReferenceArrayField>
            <ReferenceField source={"parent"} reference={"categories"}>
                <TextField source="name" />
            </ReferenceField>
            <BooleanField source="active" />
        </SimpleShowLayout>
    </Show>
);