export const orderStatus = [
    {id: "RESERVED", name: "Reserviert"},
    {id: "PICKUP_INITIATED", name: "Pickup gestartet"},
    {id: "TRANSFERRED", name: "Transferiert"},
    {id: "BORROWED", name: "Ausgeliehen"},
    {id: "RETURN_INITIATED", name: "Rückgabe gestartet"},
    {id: "WAITING_FOR_RESPONSE_PICKUP", name: "Wartet auf Box (Abholung)"},
    {id: "WAITING_FOR_RESPONSE", name: "Wartet auf Box"},
    {id: "WAITING_FOR_RESPONSE_RETURN", name: "Wartet auf Box (Rückgabe)"},
    {id: "PUSH_TIMEOUT", name: "Nicht in Box verstaut"},
    {id: "PULL_TIMEOUT", name: "Nicht aus Box genommen"},
    {id: "OVERDUE", name: "Überfällig"},
    {id: "REJECTED", name: "Abgelehnt"},
    {id: "EXPIRED", name: "Reservierung abgelaufen"},
    {id: "CANCELLED", name: "Abgebrochen"},
    {id: "COMPLETED", name: "Komplett"},
];
