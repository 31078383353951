import {BooleanInput, Edit, ReferenceArrayInput, SimpleForm, TextInput} from 'react-admin';
import React from "react";

export const CategoryEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceArrayInput reference={"categories"} source={"parent"}></ReferenceArrayInput>
            <BooleanInput source="active" />
        </SimpleForm>
    </Edit>
);