import {
    ArrayField,
    Datagrid,
    DateField,
    ImageField,
    List,
    ReferenceField, SelectField,
    SingleFieldList,
    TextField
} from 'react-admin';
import React from "react";
import {orderStatus} from "./order-status-enums";

export const OrderList = () => (
    <List sort={{ field: 'createdDate', order: 'DESC' }}>
        <Datagrid rowClick="show">
            <DateField source="createdDate"/>
            <ReferenceField source="item" reference="items" resource={"items"} link={false}>
                <ArrayField source={"images"} perPage={1}>
                    <SingleFieldList resource={"items"} linkType={false}><ImageField source="src" /></SingleFieldList>
                </ArrayField></ReferenceField>
            <ReferenceField source="item" reference="items" resource={"items"} link={"show"}>
                <TextField source={"title"}></TextField></ReferenceField>
            <TextField source="borrower.displayName"/>
            <SelectField source="orderStatus" choices={orderStatus}/>
            <TextField source="id"/>
        </Datagrid>
    </List>
);