import {
    ArrayField,
    BooleanField,
    Datagrid,
    DateField,
    EditButton,
    ImageField,
    ReferenceArrayField,
    ReferenceField,
    SelectField,
    Show,
    SimpleShowLayout,
    SingleFieldList,
    TextField,
    TopToolbar,
    useShowContext
} from 'react-admin';
import {Divider} from '@mui/material';
import React from "react";
import {orderStatus} from "../orders/order-status-enums";

const ItemShowToolbar = () => {

    const {record, isLoading} = useShowContext();
    console.log(record)

    if (record === undefined) return <TopToolbar></TopToolbar>;
    return (
        <TopToolbar>
            {record.type !== "ThingkItem" && <EditButton label="Bearbeiten"/>}
        </TopToolbar>
    );
}

export const ItemShow = () => (
    <Show actions={<ItemShowToolbar/>}>
        <SimpleShowLayout>
            <TextField source="title"/>
            <TextField source="description"/>
            <ImageField source={"images"} src={"src"}></ImageField>
            <ReferenceArrayField reference={"categories"} source={"categories"}>
                <SingleFieldList>
                    <TextField source={"name"}></TextField>
                </SingleFieldList>
            </ReferenceArrayField>

            <TextField source={"location.city"} label={"Ort"}/>
            <BooleanField source="isAvailable"/>
        </SimpleShowLayout>
        <SimpleShowLayout divider={<Divider flexItem/>}>
            <TextField source={"location.street"} label={"Straße"}/>
            <TextField source={"location.houseNumber"} label={"Hausnummer"}/>
            <TextField source={"location.zip"} label={"PLZ"}/>
            <TextField source={"location.city"} label={"Ort"}/>
        </SimpleShowLayout>
        <SimpleShowLayout divider={<Divider flexItem/>}>
            <TextField source={"price"} label={"Preis"}/>
            <TextField source={"priceUnit"} label={"Preiseinheit"}/>
            <DateField source={"expirationDate"} showTime={true} label={"Gültig bis"}/>
        </SimpleShowLayout>
        <SimpleShowLayout divider={<Divider flexItem/>}>
            <ArrayField source={"orderHistory"} label={"Letzte Transaktionen"}>
                <Datagrid unselectable={"on"} bulkActionButtons={false}>
                    <DateField source="createdDate" showTime label={"Datum"}/>
                    <ReferenceField source="id" reference="orders" resource={"orders"} link={"show"} label={"Transaktions ID"}>
                        <TextField source={"id"} label={"Transaktions ID"}></TextField></ReferenceField>
                    <SelectField source="status" choices={orderStatus} label={"Status"}/>
                    <SelectField source="transferStatus" choices={orderStatus} label={"Transfer Status"}/>
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);